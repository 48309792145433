import './App.css';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './component/Navbar';
import Midsection from './component/Midsection';
import About from './component/About';
import Contact from './component/Contact';
import Products from './component/Products';
import Footer from './component/Footer';

function App() {
  return (
    <div className="App">
      <Router>
        <Navbar />
        <Routes>
          <Route path='/' element={<Midsection />} />
          <Route path='/about' element={<About />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/products' element={<Products />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;